import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore'; // Import limit
import { db } from './firebaseConfig';
import './Profile.css'; // Import the CSS file
import logoGif from './assets/logo.gif';
import Post from './components/Post'; // Import the Post component

// Correctly import the SVG icons as React components
import { ReactComponent as YouTube } from './assets/svg/newicons/youtube.svg';
import { ReactComponent as Facebook } from './assets/svg/newicons/facebook.svg';
import { ReactComponent as Spotify } from './assets/svg/newicons/spotify.svg';
import { ReactComponent as Twitter } from './assets/svg/newicons/twitter.svg';
import { ReactComponent as Instagram } from './assets/svg/newicons/instagram.svg';
import { ReactComponent as Reddit } from './assets/svg/newicons/reddit.svg';
import { ReactComponent as Link } from './assets/svg/newicons/link.svg';
import { ReactComponent as Snapchat } from './assets/svg/newicons/snapchat.svg';
import { ReactComponent as Discord } from './assets/svg/newicons/discord.svg';
import { ReactComponent as Github } from './assets/svg/newicons/github.svg';
import { ReactComponent as TikTok } from './assets/svg/newicons/tiktok.svg';
import { ReactComponent as LinkedIn } from './assets/svg/newicons/linkedin.svg';
import { ReactComponent as MusicApple } from './assets/svg/newicons/apple.music.svg';

function Profile() {
  const { username } = useParams();
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("username", "==", username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Ensure each social link has the correct format
            if (data.socialLinks) {
              data.socialLinks = data.socialLinks.map(link => 
                link.startsWith('http') ? link : `https://${link}`
              );
            }
            setUserData(data);
          });
        } else {
          console.error("No such user!");
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    fetchUserData();
  }, [username]);

  useEffect(() => {
    const fetchUserPosts = async () => {
      if (!userData || !userData.userUid) {
        console.error("User data or userId is undefined:", userData);
        return;
      }

      try {
        const postsRef = collection(db, "posts");
        const q = query(
          postsRef,
          where("userId", "==", userData.userUid),
          where("status", "==", "live"),
          where("postprivacy", "==", "public"), // Ensure only public posts are fetched
          orderBy("timestamp", "desc"), // Order by timestamp descending
          limit(10) // Limit to 10 posts
        );
        const querySnapshot = await getDocs(q);

        const postsData = [];
        querySnapshot.forEach((doc) => {
          postsData.push({ id: doc.id, ...doc.data() });
        });
        setPosts(postsData);
      } catch (error) {
        console.error("Error fetching user posts: ", error);
      }
    };

    fetchUserPosts(); // Fetch user posts only when userData is available
  }, [userData]); // Dependency on userData

  if (!userData) {
    return <div>Loading...</div>;
  }

  const renderMediaIcon = (socialLink) => {
    if (socialLink?.toLowerCase().includes("youtube") || socialLink?.toLowerCase().includes("youtu")) {
        return <YouTube className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("facebook")) {
        return <Facebook className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("twitter")) {
        return <Twitter className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("instagram")) {
        return <Instagram className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("reddit")) {
        return <Reddit className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("spotify")) {
        return <Spotify className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("snapchat")) {
        return <Snapchat className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("tiktok")) {
        return <TikTok className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("discord")) {
        return <Discord className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("github")) {
        return <Github className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("linkedin")) {
        return <LinkedIn className="social-icon" />;
    } else if (socialLink?.toLowerCase().includes("music.apple") || socialLink?.toLowerCase().includes("apple") || socialLink?.toLowerCase().includes("music")) {
        return <MusicApple className="social-icon" />;
    } else {
        return <Link className="social-icon" />;
    }
  };

  return (
    <div className="profile-container">
      <div className="top-banner">
        <img src={logoGif} alt="Logo" className="logo-gif" />
        <p className="create-space-text">
          Create your space 👉 <a href="https://txtyapp.com" className="txty-link">txtyapp.com</a>
        </p>
        <h1 className="txty-title">txty.<span className="txty-space">space</span></h1>
      </div>
      <div className="profile-section">
        {userData.profile_picture && (
          <img 
            src={userData.profile_picture} 
            alt="Profile" 
            className="profile-picture" 
          />
        )}
        <div className="profile-details">
          <h1 className="username">@{userData.username}</h1>
          <p className="bio">{userData.bio}</p>
        </div>
      </div>
      <div className="social-links">
        {userData.socialLinks && userData.socialLinks.length > 0 && (
          <ul>
            {userData.socialLinks.map((link, index) => (
              <li key={index}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {renderMediaIcon(link)}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Display Posts */}
      <div className="posts-section">
        {posts.length > 0 ? (
          posts.map((post) => (
            <Post
              key={post.id}
              username={userData.username}
              content={post.caption}
              timestamp={post.timestamp}
              likes={post.likes}
              comments={post.comments}
              type={post.type}
              profile_picture={userData.profile_picture}
              socialLink={post.socialLink} // Pass the socialLink prop here
            />
          ))
        ) : (
          <p>No posts available.</p>
        )}
      </div>

      {/* Message to Download App */}
      <div className="download-message">
        <p>Download Txty to see more posts from @{userData.username}!</p>
      </div>
    </div>
  );
}

export default Profile;