import React from 'react';
import './Post.css';
import { ReactComponent as LikeIcon } from '../assets/svg/newicons/like.svg';
import { ReactComponent as CommentIcon } from '../assets/svg/newicons/comments.svg';
import { ReactComponent as YouTube } from '../assets/svg/newicons/youtube.svg';
import { ReactComponent as Facebook } from '../assets/svg/newicons/facebook.svg';
import { ReactComponent as Spotify } from '../assets/svg/newicons/spotify.svg';
import { ReactComponent as Twitter } from '../assets/svg/newicons/twitter.svg';
import { ReactComponent as Instagram } from '../assets/svg/newicons/instagram.svg';
import { ReactComponent as Reddit } from '../assets/svg/newicons/reddit.svg';
import { ReactComponent as Link } from '../assets/svg/newicons/link.svg';
import { ReactComponent as Snapchat } from '../assets/svg/newicons/snapchat.svg';
import { ReactComponent as Discord } from '../assets/svg/newicons/discord.svg';
import { ReactComponent as Github } from '../assets/svg/newicons/github.svg';
import { ReactComponent as TikTok } from '../assets/svg/newicons/tiktok.svg';
import { ReactComponent as LinkedIn } from '../assets/svg/newicons/linkedin.svg';
import { ReactComponent as MusicApple } from '../assets/svg/newicons/apple.music.svg';

function Post({ username, content, timestamp, likes, comments, type, profile_picture, socialLink }) {

  // Ensure the link has the correct format
  const formattedLink = socialLink && !socialLink.startsWith('http') ? `https://${socialLink}` : socialLink;

  const renderMediaIcon = (link) => {
    if (link?.toLowerCase().includes("youtube") || link?.toLowerCase().includes("youtu")) {
      return <YouTube className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("facebook")) {
      return <Facebook className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("twitter")) {
      return <Twitter className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("instagram")) {
      return <Instagram className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("reddit")) {
      return <Reddit className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("spotify")) {
      return <Spotify className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("snapchat")) {
      return <Snapchat className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("tiktok")) {
      return <TikTok className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("discord")) {
      return <Discord className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("github")) {
      return <Github className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("linkedin")) {
      return <LinkedIn className="post-social-icon" />;
    } else if (link?.toLowerCase().includes("music.apple") || link?.toLowerCase().includes("apple") || link?.toLowerCase().includes("music")) {
      return <MusicApple className="post-social-icon" />;
    } else {
      return <Link className="post-social-icon" />;
    }
  };

  return (
    <div className={`post-container ${type}`}>
      <div className="post-header">
        {profile_picture && (
          <img 
            src={profile_picture} 
            alt={`${username}'s profile`} 
            className="post-profile-picture"
          />
        )}
        <span className="post-username">@{username}</span>
        <span className="post-timestamp">{new Date(timestamp.toDate()).toLocaleString()}</span>
      </div>
      <div className="post-content">{content}</div>
      <div className="post-meta">
        {formattedLink && (
          <a href={formattedLink} target="_blank" rel="noopener noreferrer">
            {renderMediaIcon(formattedLink)}
          </a>
        )}
        <div className="post-likes">
          <LikeIcon className="post-icon" /> {likes}
        </div>
        <div className="post-comments">
          <CommentIcon className="post-icon" /> {comments}
        </div>
      </div>
    </div>
  );
}

export default Post;