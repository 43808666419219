import React, { useEffect, useState } from 'react';
import './Profile.css'; // Reuse the CSS file for consistent styling
import logoGif from './assets/logo.gif'; // Assuming the path to the GIF logo is the same

function HomeScreen() {
  const [animatedText, setAnimatedText] = useState('space');

  useEffect(() => {
    const textOptions = ['space', 'chat'];
    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % textOptions.length;
      setAnimatedText(textOptions[index]);
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="profile-container" style={styles.container}>
    <img src={logoGif} alt="Logo" className="logo-gif" style={styles.logoGif} />
      <div className="top-banner" style={styles.topBanner}>
        
        <h1 className="txty-title" style={styles.txtyTitle}>
          txty.<span className="txty-space" style={styles.txtySpace}>{animatedText}</span>
        </h1>
      </div>

      <div className="profile-details" style={styles.profileDetails}>
        <h1 className="username" style={styles.username}>What is Txty Space?</h1>
        <p className="bio" style={styles.bio}>
          Txty Space is your personal corner of the Txty universe. It's a fun and engaging platform that connects directly with the Txty app, allowing you to express yourself through text in a unique way. Here, you can create social profiles and showcase your latest public posts for the world to see.
        </p>
        <p className="bio" style={styles.bio}>
          To get started, download the Txty app on iOS and Android. Once you create an account, your personal space will be live at txty.space/yourusername. It's that simple!
        </p>
        <p className="bio" style={styles.bio}>
          Join the community and start sharing your text-based updates with the world. Let your voice be heard, one text at a time!
        </p>
        <p className="bio" style={styles.bio}>
        More informatino can be found at txtyapp.com</p>
      </div>

      <div className="download-message" style={styles.downloadMessage}>
        <p>Download Txty now and claim your space!</p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  topBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  logoGif: {
    width: '80px',
    height: '80px',
    marginRight: '20px',
  },
  txtyTitle: {
    fontSize: '48px', // Increase size for emphasis
    color: '#7d68f5',
  },
  txtySpace: {
    color: '#ff69b4',
    transition: 'opacity 0.5s ease-in-out', // Smooth transition for text change
  },
  profileDetails: {
    textAlign: 'center',
    marginTop: '30px',
  },
  username: {
    fontSize: '32px',
    color: '#7d68f5',
    marginBottom: '20px',
  },
  bio: {
    fontSize: '18px',
    color: '#1a1a1a',
    marginBottom: '20px',
    lineHeight: '1.6',
  },
  downloadMessage: {
    marginTop: '40px',
    fontSize: '20px',
    color: '#7d68f5',
  },
};

export default HomeScreen;